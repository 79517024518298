import { useContext, useEffect, useState } from "react";
import { NavBar } from "./home_page";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { uuid } from "./projects_page";
import { db } from "../../firebase";
import { CurrentUserContext } from "../../App";

// const body = [
//   [
//     "Mars-Avril 2023",
//     "L’élaboration du Projet de décret fixant la mission et la composition de la Commission Technique chargée de la Promotion immobilière.",
//     "Ministère de l'Habitat, de l'Urbanisme et de l'Aménagement du Territoire",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Février au Avril 2023",
//     "l’Actualisation du Plan d’Action d’Éducation Environnementale et Sensibilisation dans la zone du Parc National du Diawling",
//     "PND/Financé par BACOMAB",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Novembre 2021- Mai2022",
//     "Elaboration d’un ‘’Tableau de bord’’ basé sur un système d'indicateurs biophysiques, socio-économiques et de gouvernance du Parc National du Diawling’’",
//     "PND/Financé par UEABS2015-2019",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Juin-Novembre 2021",
//     "préparation du dossier technique pour l’inscription du PND sur la liste du patrimoine mondial de l’UNESCO",
//     "PND/Financé par UEABS2015-2019",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Décembre 2021- Février2022",
//     "LA TRANSCRIPTION INFOGRAPHIQUE DE 2 MODULES DE FORMATION",
//     "IRAM",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "OctobreDécembre 2015",
//     "l’élaboration d’un SIG en interface avec la base de données de la DCE sur les études d’impact environnemental et le contrôle environnemental",
//     "BGP-DCE/Financé par PNUD",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Avril au mai 2020",
//     "l’élaboration d’une Base de données au profit du Parc National d’Awleigatt (PNA)",
//     "PNA/MEDD",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Octobredécembre 2019",
//     "Mettre en place un SIG et la Base de données répondant aux exigences de l’Observatoire du Parc National du Diawling",
//     "PND /financé par UE",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Janvier 2020",
//     "CARTOGRAPHIE DE LA MANGROVE DE LA RESERVE DE BIOSPHERE TRANSFRONTIERE bas delta du fleuve Sénégal, rive droite",
//     "WACA",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Décembre 2018",
//     "Cartographie et Caractérisation des site critiques pour le suivi des petites pélagiques dans le Parc National du Diawling",
//     "PND- MAVA",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Oct. 2017 - Nov. 2017",
//     "Atelier de réflexion sur «Régions Et Régionalisation : Etats de lieux, atouts, faiblesses et portes d’entrée de l’action régionale pour la zone du Nord» à Atar",
//     "Ministère de l’habitat, de l’urbanisme et de l’aménagement du territoire DATAR",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Novembre 2018",
//     "Formation des cadres et agents du PND sur les techniques de SIG et localisation par GPS",
//     "PND",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Décembre 2017",
//     "Cartographie des ressources, territoires, des potentialités, des acteurs et la formation de l’équipe d’Oxfam à Aion sur les logiciels de cartographie ArcGis et QGIS",
//     "RIMRAP-Oxfam",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Septembre 2014",
//     "Séminaire de formation sur « Système d’Information géographique »",
//     "SAM, CDD, DAT, AR, STP, MFP, Vaincre, CSA (20 participant)",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Avril-2015",
//     "Séminaire de formation sur « Exploitation de données Statistiques avec logiciel SPSS»",
//     "SAM, Vaincre, Mauripost, CNSS",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Aout 2015",
//     "Séminaire de formation sur « Système de Gestion de base de données SGBD»",
//     "PNBA, Ministère de finance, STP, AMI",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Mars 2016",
//     "formation sur Système d’Information géographique Avec ArcGis (10.1)",
//     "Direction d’aménagement du territoire",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Février 2015",
//     "Séminaire de formation sur Excel avancé",
//     "PND,SAM, CDD, AR, STP, MFP, Vaincre, CSA (20 participant)",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "JUIN 2020",
//     "l’élaboration d’un guide de sensibilisation sur les bonnes pratiques nutritionnelles, hygiéniques et familiales",
//     "Oxfam",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Mars-2019",
//     "Boîte à images pour l’animation des séances des espaces sûrs en Mauritanie",
//     "SWEDDMauritanie (Ministère de la Jeunesse et des Sports, Ministère de l'Education Nationa le et de la Formation professionnelle, Ministère des Affaires Sociales, de l'Enfance et de la Famille)",
//     "Mauritanie",
//     "Consultant seul",
//   ],

//   [
//     "Avril-2019",
//     "L’élaboration d’une boîte à images pour sensibilisation publique à l’éducation Environnementale",
//     "PND (financé par WACA)",
//     "Mauritanie",
//     "Consultant seul",
//   ],
//   [
//     "Novembre 2016",
//     "Elaboration d’un livret pour l’Education à l’Environnement Et formation des formateurs dans le Parc National du Diawling",
//     "PND (financé par BacoMAB)",
//     "Mauritanie",
//     "Consultant seul",
//   ],
// ];

function DefInput({
  label,
  value,
  type = "text",
  onChange = () => {},
}: {
  label: string;
  value: string;
  type?: string;
  onChange?: (value: string) => void;
}) {
  return (
    <div className="flex flex-col gap-y-2 items-start w-full">
      <label className="text-sm text-black">{label}</label>
      {type === "area" ? (
        <textarea
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value}
          className="border rounded-xl py-3 px-4 w-full"
        />
      ) : (
        <input
          type={type}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value}
          className="border rounded-xl py-3 px-4 w-full"
        />
      )}
    </div>
  );
}
// function bulkUpload() {
//   let i = 0;
//   for (let b of body) {
//     addDoc(collection(db, "experience"), {
//       duration: b[0],
//       mission: b[1],
//       client: b[2],
//       country: b[3],
//       role: b[4],
//     });
//     i++;
//     console.log(i + "/" + body.length);
//   }
// }
interface Experience {
  id: string;
  duration: string;
  mission: string;
  client: string;
  country: string;
  role: string;
}
function AddInfoDialog({ onSubmit }: { onSubmit: () => void }) {
  const [duration, setDuration] = useState("");
  const [mission, setMission] = useState("");
  const [client, setClient] = useState("");
  const [country, setCountry] = useState("");
  const [role, setRole] = useState("");

  const [isUploading, setIsUploading] = useState(false);

  async function upload() {
    setIsUploading(true);
    const uid = uuid();

    try {
      await addDoc(collection(db, "experience"), {
        duration,
        mission,
        client,
        country,
        role,
        dateCreated: new Date().toISOString(),
      });
    } catch (e) {
      console.error(e);
    }
    setIsUploading(false);
    onSubmit();
  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="bg-white shadow-xl rounded-xl p-4 w-[800px]  justify-between flex flex-col items-end"
    >
      <div className=" grid grid-cols-2 gap-y-2 gap-x-4 w-full ">
        <div className=" col-span-2">
          <DefInput
            label="Durée"
            value={duration}
            onChange={(value) => {
              setDuration(value);
            }}
          />
        </div>
        <DefInput
          label="Pays"
          value={country}
          onChange={(value) => {
            setCountry(value);
          }}
        />
        <DefInput
          label="Rôle"
          value={role}
          onChange={(value) => {
            setRole(value);
          }}
        />
        <DefInput
          label="Mission"
          type="area"
          value={mission}
          onChange={(value) => {
            setMission(value);
          }}
        />
        <DefInput
          label="Client"
          type="area"
          value={client}
          onChange={(value) => {
            setClient(value);
          }}
        />
      </div>
      <button
        onClick={upload}
        disabled={
          duration === "" ||
          mission === "" ||
          client === "" ||
          country === "" ||
          role === "" ||
          isUploading
        }
        className={` bg-blue-500 rounded-full w-min text-white py-2 px-4  hover:bg-blue-600 active:scale-95 transition-all disabled:bg-gray-500 mt-8 ${
          isUploading ? " animate-pulse" : ""
        }`}
      >
        {/* uploading in french */}
        {isUploading ? "Téléchargement..." : "Ajouter"}
      </button>
    </div>
  );
}

export function ConfirmatinDialog({
  onSubmit,
  onCancel,
}: {
  onSubmit: () => void;
  onCancel: () => void;
}) {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="bg-white shadow-xl rounded-xl p-6 w-[600px] flex-col flex gap-y-3 "
    >
      <div className="p-2 rounded-full bg-red-300 w-min self-center">
        <svg
          viewBox="0 0 24 24"
          className="h-4 w-4 fill-red-600 stroke-red-600"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.0618 4.4295C12.6211 3.54786 11.3635 3.54786 10.9228 4.4295L3.88996 18.5006C3.49244 19.2959 4.07057 20.2317 4.95945 20.2317H19.0252C19.914 20.2317 20.4922 19.2959 20.0947 18.5006L13.0618 4.4295ZM9.34184 3.6387C10.4339 1.45376 13.5507 1.45377 14.6428 3.63871L21.6756 17.7098C22.6608 19.6809 21.228 22 19.0252 22H4.95945C2.75657 22 1.32382 19.6809 2.30898 17.7098L9.34184 3.6387Z"
              fill="#1C1C1C"
            ></path>{" "}
            <path
              d="M12 8V13"
              stroke="#1C1C1C"
              stroke-width="1.7"
              stroke-linecap="round"
            ></path>{" "}
            <path
              d="M12 16L12 16.5"
              stroke="#1C1C1C"
              stroke-width="1.7"
              stroke-linecap="round"
            ></path>{" "}
          </g>
        </svg>
      </div>
      <h3 className="text-2xl font-semibold text-center text-black">
        Êtes-vous sûr ?
      </h3>
      <p className="text-center text-gray-500">
        Cette action ne peut pas être annulée.
      </p>
      <button
        onClick={onSubmit}
        className="py-3 font-semibold bg-red-400 border rounded-md text-white shadow-sm border-red-400 transition-all hover:bg-red-500 active:scale-95"
      >
        Supprimer
      </button>
      <button
        onClick={onCancel}
        className="py-3 bg-transparent border rounded-xl text-black shadow-sm border-slate-400 hover:bg-slate-100 transition-all active:scale-95"
      >
        Annuler
      </button>
    </div>
  );
}

export function InfoPage() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [data, setData] = useState<Experience[] | null>(null);
  const [deletingDoc, setDeletingDoc] = useState<string | null>(null);
  const user = useContext(CurrentUserContext).user;

  async function deleteExperience(id: string) {
    await deleteDoc(doc(db, "experience", id));
    fetch();
  }

  async function fetch() {
    setData(null);
    var q = query(collection(db, "experience"), orderBy("dateCreated", "desc"));

    let ret: Experience[] = [];
    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      const data = doc.data();

      ret.push({
        id: doc.id,
        duration: data.duration,
        mission: data.mission,
        client: data.client,
        country: data.country,
        role: data.role,
      });
    }

    setData(ret);
  }

  useEffect(() => {
    fetch();
  }, []);
  return (
    <div className="flex flex-col w-full">
      <NavBar />
      {(isDialogOpen || deletingDoc) && (
        <div
          onClick={() => {
            setIsDialogOpen(false);
            setDeletingDoc(null);
          }}
          className=" absolute inset-0 flex justify-center items-center  backdrop-blur-sm z-50"
        >
          {deletingDoc ? (
            <ConfirmatinDialog
              onSubmit={function (): void {
                deleteExperience(deletingDoc);
                setDeletingDoc(null);
              }}
              onCancel={function (): void {
                setDeletingDoc(null);
              }}
            />
          ) : (
            <AddInfoDialog
              onSubmit={function (): void {
                setIsDialogOpen(false);
                fetch();
              }}
            />
          )}
        </div>
      )}
      <div className="flex flex-col md:flex-row justify-between my-4 md:my-12 gap-y-4 items-start md:items-center gap-x-2  w-full px-4">
        <h3 className=" text-4xl font-semibold   text-slate-700 ">
          EXPÉRIENCE DU CONSULTANT
        </h3>
        {user && (
          <button
            onClick={() => {
              setIsDialogOpen(true);
            }}
            className="border border-blue-500 rounded-full py-2 px-4 font-semibold text-blue-600 transition-all hover:bg-blue-500 hover:text-white active:scale-95"
          >
            Ajouter
          </button>
        )}
      </div>
      {data ? (
        <div className=" overflow-x-scroll">
          <div className=" shadow-sm mb-20 mx-4 w-[1200px] overflow-x-scroll text-xs xl:w-fit">
            <MTable
              titles={[
                "Durée",
                "Mission",
                "Client - Référence",
                "Pays",
                "Rôle",
                ...(user ? [""] : []),
              ]}
              body={data!.map((d) => {
                return [
                  d.duration,
                  d.mission,
                  d.client,
                  d.country,
                  d.role,
                  ...(user
                    ? [
                        <button
                          onClick={(e) => {
                            setDeletingDoc(d.id);
                            e.stopPropagation();
                          }}
                          className="text-white font-semibold px-4 py-2 rounded-sm bg-red-500 hover:bg-red-400 active:scale-95 transition-all"
                        >
                          Supprimer
                        </button>,
                      ]
                    : []),
                ];
              })}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full grow mt-20 ">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-gray-200 animate-spin  fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
}

interface MTableProps {
  titles: string[];
  body: any[][];
  maxRows?: number;
  selected?: number[];
  onCheckboxClick?: (index: number) => void | null;
  onRowClick?: (index: number) => void;
  smallSizeLastColumn?: boolean;
  roundedBottom?: boolean;
  dateCreated?: string;
}

export function MTable({
  titles,
  body,
  maxRows = 10,
  selected = [],
  roundedBottom = true,
  smallSizeLastColumn = false,
  onCheckboxClick,
  onRowClick = () => {},
}: MTableProps) {
  return (
    <table
      className={`w-full table-fixed border-separate border-spacing-0 rounded-t-xl border border-gray-300 shadow-sm ${
        roundedBottom ? "rounded-b-xl" : ""
      }`}
    >
      <thead>
        <tr className="bg-gray-100">
          {onCheckboxClick && (
            <th className="w-12 rounded-tl-xl border-b text-center">
              <input
                type="checkbox"
                onClick={() => {
                  onCheckboxClick(-1);
                }}
                checked={body.length > 0 && selected.length === body.length}
                className="form-checkbox h-5 w-5 rounded text-blue-600 ring-0 transition-all focus:ring-0"
              />
            </th>
          )}
          {titles.map((title) => {
            return (
              <th
                className={`firs last:border-r-xl border-b py-4 pl-4 text-left text-sm font-semibold text-gray-500 first:rounded-tl-xl last:rounded-tr-xl ${
                  smallSizeLastColumn ? "last:w-32" : ""
                }`}
              >
                {title}
              </th>
            );
          })}
          {/* <th className="border-r-xl w-28  rounded-tr-xl  border-b text-left text-sm font-semibold text-gray-500">
                    Actions
                  </th> */}
        </tr>
      </thead>
      <tbody>
        {body.map((row, index) => {
          return (
            <tr
              onClick={() => {
                onRowClick(index);
              }}
              className="group cursor-pointer  "
            >
              {[
                onCheckboxClick && (
                  <td className="border-b py-3 text-center group-last:border-0 group-hover:bg-slate-200 ">
                    <input
                      type="checkbox"
                      checked={selected.includes(index)}
                      onClick={() => {
                        onCheckboxClick(index);
                      }}
                      className="form-checkbox h-5 w-5 justify-self-center rounded text-blue-600 ring-0 transition-all focus:ring-0 "
                    />
                  </td>
                ),
                ...row.map((cell) => {
                  return (
                    <td
                      className={`border-b py-4 pl-4 text-left text-sm font-light text-black last:w-24   group-last:border-0 group-hover:bg-slate-200 ${
                        roundedBottom ? "group-last:rounded-b-xl" : ""
                      }`}
                    >
                      {cell}
                    </td>
                  );
                }),
              ]}
            </tr>
          );
        })}
        {/* {Array(maxRows - body.length)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <tr className="hover:bg-slate-200">
                        {Array(titles.length + 1)
                          .fill(0)
                          .map((_, index) => {
                            return (
                              <td className="px-4 py-4 text-left text-sm font-light text-black invisible">s</td>
                            );
                          })}
                      </tr>
                    );
                  })} */}
      </tbody>
    </table>
  );
}
