import { useContext, useEffect, useState } from "react";
import img1 from "../../assets/images/b1.jpeg";
import img2 from "../../assets/images/b2.jpeg";
import img3 from "../../assets/images/b3.jpeg";
import { NavBar } from "./home_page";
import { collection, doc, getDocs, query, setDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { uuid } from "./projects_page";
import { CurrentUserContext } from "../../App";
import DefInput from "../components/def_input";

export interface New {
  id: string;
  title: string;
  type: string;
  image: string | null;
  link: string | null;
}

export function NewsItem({
  item,
  mini = false,
}: {
  item: New | null;
  mini?: boolean;
}) {
  return (
    <div
      className={`flex-col items-start   2xl:w-[480px]  ${
        !item ? "animate-pulse" : ""
      } ${mini ? "w-[300px]" : "w-[94%]"}`}
    >
      <a
        href={item?.link ?? `/news/${item?.id}`}
        target={item?.link ? "_blank" : "_self"}
      >
        {!mini &&
          (item ? (
            <h6 className="text-blue-500 font-semibold mb-2  text-left ">
              {item.type}
            </h6>
          ) : (
            <div className="h-2.5 bg-gray-200 rounded-full w-56 mb-2"></div>
          ))}
        {item && item.image ? (
          <img
            className={`w-full  rounded-xl shadow-xl object-cover  ${
              mini ? " h-72" : "h-96"
            } `}
            src={item.image}
          />
        ) : (
          <div
            className={`flex items-center justify-center  w-full bg-gray-300 rounded-xl ${
              mini ? " h-72" : "h-96"
            }`}
          >
            <svg
              className="w-10 h-10 text-gray-200 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 20"
            >
              <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
              <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
            </svg>
          </div>
        )}
        {item ? (
          <h3 className=" text-xl text-left mt-6 line-clamp-2 font-medium">
            {item.title}
          </h3>
        ) : (
          <div className="h-2.5 bg-gray-200 rounded-full  w-96 mt-6"></div>
        )}
      </a>
    </div>
  );
}
function NewsDialog({ onSubmit }: { onSubmit: () => void }) {
  const [img, setImg] = useState<{ file: File; url: string } | null>(null);
  const [title, setTitle] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [isUploading, setIsUploading] = useState(false);
  const [newType, setNewType] = useState<"link" | "detail">("link");
  const [detail, setDetail] = useState<string>("");

  async function uploadNew() {
    setIsUploading(true);
    const ext = img?.file.name.split(".").pop();
    const uid = uuid();
    const imageRef = ref(storage, `news/${uid}.${ext}`);

    let url = null;
    if (newType === "detail") {
      if (detail.trim() === "") {
        alert("Le texte est vide");
        setIsUploading(false);
        return;
      }
    } else {
      if (link.trim() !== "") {
        try {
          url = new URL(link);
        } catch (e) {
          alert("Le lien n'est pas valide");
          setIsUploading(false);
          return;
        }
      }
    }

    try {
      await setDoc(doc(db, "news", uid), {
        title,
        image: `news/${uid}.${ext}`,
        type: type,
        link: newType === "link" ? url?.href ?? null : null,
        detail: newType === "detail" ? detail : null,
      });

      await uploadBytes(imageRef, img?.file as Blob);
    } catch (e) {
      console.error(e);
    }
    setIsUploading(false);
    onSubmit();
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`bg-white shadow-xl rounded-xl p-4 aspect-[3/2] transition-all flex-col flex gap-y-3 ${
        newType === "link" ? "w-[600px]" : "w-[800px]"
      }`}
    >
      <div className="flex items-center justify-center w-full">
        {img ? (
          <img
            className="w-full h-64 rounded-xl shadow-xl object-cover "
            src={img.url}
          />
        ) : (
          <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>

              <p className="text-xs text-gray-500 ">SVG, PNG, JPG</p>
            </div>
            <input
              onChange={(e) => {
                const file = e.target.files?.item(0);

                if (file) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    setImg({
                      file: file,
                      url: e.target?.result as string,
                    });
                  };
                  reader.readAsDataURL(file);
                }
              }}
              id="dropzone-file"
              type="file"
              className="hidden"
            />
          </label>
        )}
      </div>
      <input
        placeholder="Titre"
        className=" text-xl p-3 border border-slate-300 rounded shadow-sm"
        value={title}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => setTitle(e.target.value)}
      />

      <input
        placeholder="Type"
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => setType(e.target.value)}
        className=" text-md p-3 border border-slate-300 rounded shadow-sm"
        value={type}
      />
      <div className="flex gap-x-2 my-2 items-center">
        <label className="font-semibold">Status :</label>
        <select
          id="newType"
          name="newType"
          className="border border-slate-300 rounded shadow-sm py-1 px-2"
          value={newType}
          onChange={(e) => {
            setNewType(e.target.value as "link" | "detail");
          }}
        >
          <option value="link">Lien externe</option>
          <option value="detail">Lien Interne</option>
        </select>
      </div>
      <hr className="w-full border-slate-300 mb-2"></hr>

      <div
        className={`transition-all w-full ${
          newType === "link" ? "min-h-0" : "min-h-[200px]"
        }`}
      >
        {newType === "link" ? (
          <DefInput
            placeholder="Lien"
            className="w-full"
            value={link}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => setLink(e.target.value)}
          />
        ) : (
          <textarea
            // value={detail}
            onChange={(
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              setDetail(e.target.value.replaceAll("\n", "<br/>"));
            }}
            className=" text-lg p-3 border border-slate-300 rounded shadow-sm w-full h-full"
            placeholder="Ecrire votre texte ici"
          />
        )}
      </div>
      <button
        onClick={uploadNew}
        disabled={title === "" || img === null || isUploading}
        className={`bg-blue-500 rounded-full w-min text-white py-2 px-4 self-end hover:bg-blue-600 active:scale-95 transition-all disabled:bg-gray-500 ${
          isUploading ? " animate-pulse" : ""
        }`}
      >
        {/* uploading in french */}
        {isUploading ? "Téléchargement..." : "Ajouter"}
      </button>
    </div>
  );
}

export function NewsPage() {
  const [news, setNews] = useState<New[] | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const user = useContext(CurrentUserContext).user;

  async function fetch() {
    setNews(null);
    var q = query(collection(db, "news"));

    let ret: New[] = [];
    const querySnapshot = await getDocs(q);

    for (const doc of querySnapshot.docs) {
      const data = doc.data();

      const image = ref(storage, data.image);
      let url = null;
      try {
        url = await getDownloadURL(image);
      } catch (e) {
        console.error(e);
      }

      ret.push({
        id: doc.id,
        title: data.title,
        image: url,
        type: data.type,
        link: data.link,
      });
    }

    setNews(ret);
  }

  useEffect(() => {
    fetch();
  }, []);
  return (
    <div className="flex flex-col w-full items-stretch">
      <NavBar />
      {isDialogOpen && (
        <div
          onClick={() => {
            setIsDialogOpen(false);
          }}
          className=" absolute inset-0 flex justify-center items-center  backdrop-blur-sm z-50"
        >
          <NewsDialog
            onSubmit={function (): void {
              setIsDialogOpen(false);
              fetch();
            }}
          />
        </div>
      )}
      <div className="flex flex-col 2xl:mx-auto text-slate-700 ">
        <div className="flex justify-between gap-x-2 items-center w-full px-4">
          <h3 className=" text-4xl font-semibold my-12   self-start text-slate-700 ">
            Actualité
          </h3>
          {user && (
            <button
              onClick={() => {
                setIsDialogOpen(true);
              }}
              className="border border-blue-500 rounded-full py-2 px-4 font-semibold text-blue-600 transition-all hover:bg-blue-500 hover:text-white active:scale-95"
            >
              Ajouter
            </button>
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 justify-items-center 2xl:gap-x-4 gap-y-12 w-full  ">
          {news?.map((item) => <NewsItem item={item} />) ??
            [1, 2, 3].map((item) => <NewsItem item={null} />)}
        </div>
      </div>
    </div>
  );
}

export const Banner = ({ news }: { news: New[] }) => {
  return (
    <div className=" relative w-full h-[360px] overflow-hidden ">
      <div className=" absolute flex">
        <section className="flex animate-infinyScroll gap-x-3">
          {news.map((newItem) => (
            <NewsItem mini={true} item={newItem} />
          ))}
        </section>
        <section className="flex animate-infinyScroll gap-x-3 pl-3">
          {news.map((newItem) => (
            <NewsItem mini={true} item={newItem} />
          ))}
        </section>
        <section className="flex animate-infinyScroll gap-x-3">
          {news.map((newItem) => (
            <NewsItem mini={true} item={newItem} />
          ))}
        </section>
      </div>
    </div>
  );
};
