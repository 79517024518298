import { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

import logoT from "../../assets/images/l1.jpeg";
import env from "../../assets/images/services/env.jpg";
import geo from "../../assets/images/services/geo.jpg";
import education from "../../assets/images/services/education.jpg";
import humans from "../../assets/images/services/humans.jpg";
import info from "../../assets/images/services/info.jpg";
import formation from "../../assets/images/services/formation.jpg";

import back from "../../assets/images/back.png";
import group from "../../assets/images/Group.png";

import bg from "../../assets/images/bg1.jpeg";

import sponsor01 from "../../assets/images/sponsor/01.png";
import sponsor02 from "../../assets/images/sponsor/02.png";
import sponsor03 from "../../assets/images/sponsor/03.png";
import sponsor04 from "../../assets/images/sponsor/04.png";
import sponsor05 from "../../assets/images/sponsor/05.png";
import sponsor06 from "../../assets/images/sponsor/06.png";
import sponsor07 from "../../assets/images/sponsor/07.png";
import sponsor08 from "../../assets/images/sponsor/08.png";
import sponsor12 from "../../assets/images/sponsor/12.png";
import { addDoc, collection, getDocs, query } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { CurrentUserContext } from "../../App";
import { Banner, New } from "./news_page";
import { getDownloadURL, ref } from "firebase/storage";
import { getAuth, signOut } from "firebase/auth";

export function HomePage() {
  const user = useContext(CurrentUserContext).user;
  const [news, setNews] = useState<New[] | null>(null);

  useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    setNews(null);
    var q = query(collection(db, "news"));

    let ret: New[] = [];
    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      const data = doc.data();

      const image = ref(storage, data.image);
      let url = null;
      try {
        url = await getDownloadURL(image);
      } catch (e) {
        console.error(e);
      }

      ret.push({
        id: doc.id,
        title: data.title,
        image: url,
        type: data.type,
        link: data.link,
      });
    }

    setNews(ret);
  }
  return (
    <div className="flex flex-col bg-blue-50 scroll-smooth">
      <section className="relative justify-end h-[400px] lg:h-[700px] w-full mb-20 ">
        <div className="absolute top-0 inset-x-0 z-50 ">
          <NavBar />
        </div>
        <img src={bg} className="h-full w-full object-cover" />
      </section>
      <section className=" flex  ">
        <div
          id="info"
          className="xl:mx-auto xl:w-2/3 my-auto mx-10  flex flex-col lg:flex-row  gap-x-6  gap-y-10"
        >
          <img
            src={logoT}
            className=" w-[300px]  lg:w-[400px] rounded-xl shadow-xl self-center"
          />

          <ul className="text-start gap-y-3 list-disc ml-10 my-auto">
            <li>
              Bureau El Moustapha Consulting (BEMC) est un cabinet
              pluridisciplinaire créé en 2013.
            </li>
            <li>
              Il capitalise une expérience avérée en renforcement de capacité.
              Il dispose d’un personnel pluridisciplinaire permanent et
              temporaire de grande réputation dans les domaines suivants:
              <ul className="ml-10 mt-3 list-decimal font-medium">
                <li>Système d’Information Géographique</li>
                <li>L’environnement</li>
                <li>Solutions informatiques.</li>
                <li>Séminaires et programmes de formation</li>
                <li>Ingénierie de formation</li>
                <li>Organisation et ressources humaines</li>
              </ul>
            </li>
          </ul>
        </div>
      </section>
      <h1 className=" text-4xl font-semibold mx-auto my-32  text-slate-700">
        Actualités
      </h1>
      {news && <Banner news={news!} />}
      <h1 className=" text-4xl font-semibold mx-auto my-32  text-slate-700">
        Nos Services
      </h1>
      <div className="grid grid-cols-1 mx-auto md:grid-cols-2 xl:grid-cols-3 gap-y-10 justify-items-center gap-x-4 md:gap-x-8 ">
        {services.map((service, i) => (
          <div className="flex flex-col gap-y-4 bg-white shadow rounded-xl py-3 md:py-6 px-4  md:px-10 w-[min(80%,400px)] xl:w-[400px] border border-gray-300 mx-6">
            <img src={service.image} className="w-60 h-60 self-center" />
            <h1 className="text-center font-bold text-base md:text-xl">
              {service.title}
            </h1>
            <p className="text-start">{service.description}</p>
          </div>
        ))}
      </div>
      <h1 className=" text-4xl font-semibold mx-auto my-32 text-slate-700">
        Ils nous font confiance
      </h1>
      <div className="flex flex-wrap items-center justify-around md:gap-x-2 md:justify-center gap-y-4 ">
        {sponsors.map((sponsor, i) => (
          <div className=" w-[40%] md:w-[200px] bg-white border rounded-xl p-6 aspect-[3/2]">
            <img src={sponsor} className="h-full mx-auto" />
          </div>
        ))}
      </div>
      {!user && (
        <h1 className="hidden xl:block text-4xl font-semibold mx-auto  mt-32 text-slate-700">
          Nous Contactez
        </h1>
      )}
      {!user && (
        <div className="flex flex-col items-center gap-y-12 mx-4 lg:mx-20 lg:flex-row lg:gap-x-20 xl:w-2/3 xl:mx-auto mt-32">
          <div className="flex flex-col items-start lg:w-1/2">
            <h2 className="text-3xl font-semibold mb-9 mt-4 ">
              Entrer en contact
            </h2>
            <p className="text-start">
              Utilisez notre formulaire de contact pour toutes demandes
              d'informations ou contactez-nous directement en utilisant les
              coordonnées ci-dessous
              <br />
              <br />
              N'hésitez pas à nous contacter
            </p>
            <hr className="h-px mt-3 mb-10 w-full border-0 bg-gray-300" />
            <div className="flex text-start">
              <svg
                version="1.0"
                id="Layer_1"
                className="h-6 w-6 mr-4  text-blue-500"
                xmlns="http://www.w3.org/2000/svg"
                // xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 64 64"
                enable-background="new 0 0 64 64"
                // xml:space="preserve"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="currentColor"
                    d="M32,0C18.746,0,8,10.746,8,24c0,5.219,1.711,10.008,4.555,13.93c0.051,0.094,0.059,0.199,0.117,0.289l16,24 C29.414,63.332,30.664,64,32,64s2.586-0.668,3.328-1.781l16-24c0.059-0.09,0.066-0.195,0.117-0.289C54.289,34.008,56,29.219,56,24 C56,10.746,45.254,0,32,0z M32,32c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S36.418,32,32,32z"
                  ></path>
                </g>
              </svg>
              <ul>
                <li className="font-semibold ">Notre adresse</li>
                <li className=" italic text-gray-500">
                  Ilot ANC-Ksar, Lot n°890, Nouakchott, Mauritania.
                </li>
              </ul>
            </div>
            <div className="flex text-start mt-4 ">
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-4  text-blue-500"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z"
                    fill="currentColor"
                  ></path>{" "}
                </g>
              </svg>
              <ul>
                <li className="font-semibold ">Téléphone</li>
                <li className=" italic text-gray-500">+222 46426996</li>
                <li className=" italic text-gray-500">+222 32182029</li>
              </ul>
            </div>
            <div className="flex text-start mt-4">
              <svg
                height="200px"
                width="200px"
                version="1.1"
                className="h-6 w-6 mr-4  text-blue-500 fill-blue-500"
                id="_x32_"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <style type="text/css"> </style>{" "}
                  <g>
                    {" "}
                    <path
                      className=""
                      d="M440.917,67.925H71.083C31.827,67.925,0,99.752,0,139.008v233.984c0,39.256,31.827,71.083,71.083,71.083 h369.834c39.255,0,71.083-31.827,71.083-71.083V139.008C512,99.752,480.172,67.925,440.917,67.925z M178.166,321.72l-99.54,84.92 c-7.021,5.992-17.576,5.159-23.567-1.869c-5.992-7.021-5.159-17.576,1.87-23.567l99.54-84.92c7.02-5.992,17.574-5.159,23.566,1.87 C186.027,305.174,185.194,315.729,178.166,321.72z M256,289.436c-13.314-0.033-26.22-4.457-36.31-13.183l0.008,0.008l-0.032-0.024 c0.008,0.008,0.017,0.008,0.024,0.016L66.962,143.694c-6.98-6.058-7.723-16.612-1.674-23.583c6.057-6.98,16.612-7.723,23.582-1.674 l152.771,132.592c3.265,2.906,8.645,5.004,14.359,4.971c5.706,0.017,10.995-2.024,14.44-5.028l0.074-0.065l152.615-132.469 c6.971-6.049,17.526-5.306,23.583,1.674c6.048,6.97,5.306,17.525-1.674,23.583l-152.77,132.599 C282.211,284.929,269.322,289.419,256,289.436z M456.948,404.771c-5.992,7.028-16.547,7.861-23.566,1.869l-99.54-84.92 c-7.028-5.992-7.861-16.546-1.869-23.566c5.991-7.029,16.546-7.861,23.566-1.87l99.54,84.92 C462.107,387.195,462.94,397.75,456.948,404.771z"
                    ></path>{" "}
                  </g>{" "}
                </g>
              </svg>
              <ul>
                <li className="font-semibold ">Notre Email</li>
                <li className=" italic text-gray-500">bemcmr@yahoo.fr</li>
                <li className=" italic text-gray-500">
                  Ahmedelmoustapha@yahoo.fr
                </li>
              </ul>
            </div>
          </div>

          <ContactForm />
        </div>
      )}
      <div className=" mx-auto mt-10">
        <a
          href="https://www.linkedin.com/in/ahmed-el-moustapha-a05030150?originalSubdomain=mr"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-10 h-10"
            viewBox="0 0 24 24"
          >
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
          </svg>
        </a>
      </div>
      <div className="mx-auto my-8 ">© 2024 tous droits réservés</div>
    </div>
  );
}
{
  /* <a href="" className="flex items-center space-x-3 rtl:space-x-reverse">
          <div className="p-1 bg-white rounded-full">
            <img src={logo} className="h-9" />
          </div>
          <span className="self-center text-2xl font-semibold whitespace-nowrap  text-white">
            BEMC
          </span>
        </a> */
}
export function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const nav = useNavigate();
  const user = useContext(CurrentUserContext).user;

  const auth = getAuth();

  return (
    <nav className=" border-gray-200 ">
      <div className="w-full flex justify-end items-start lg:flex-row lg:px-[14%] relative p-4    ">
        <div
          className={`w-full bg-white -mx-4 lg:bg-transparent shadow-xl lg:shadow-none block z-10 absolute translate-y-10 lg:translate-y-0 lg:static lg:inset-x-[14%] ${
            !isMenuOpen && "hidden lg:block"
          }`}
          // className={`${
          //   isMenuOpen ? "block" : "hidden"
          // } w-full lg:block lg:w-auto`}
          id="navbar-default "
        >
          <ul className="font-medium flex flex-col lg:p-0 mt-4 border border-gray-100 rounded-lg  lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0">
            <li>
              <a
                href="/"
                className="block py-2 px-3 text-blue-500 bg-blue-700 rounded lg:bg-transparent lg:text-blue-500  lg:hover:text-blue-700 lg:p-0 "
                aria-current="page"
              >
                Acceuil
              </a>
            </li>
            <li>
              <a
                href="/gallery"
                className="block py-2 px-3 text-blue-500 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 "
              >
                Nos formations
              </a>
            </li>
            <li>
              <a
                href="/portef"
                className="block py-2 px-3 text-blue-500 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 "
              >
                Porte feuille
              </a>
            </li>
            <li>
              <a
                href="/projects"
                className="block py-2 px-3 text-blue-500 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 "
              >
                Projects
              </a>
            </li>
            {user && (
              <li>
                <a
                  href="/news"
                  className="block py-2 px-3 text-blue-500 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 "
                >
                  Actualités
                </a>
              </li>
            )}
            {user && (
              <li>
                <a
                  href="/messages"
                  className="block py-2 px-3 text-blue-500 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 "
                >
                  Messages
                </a>
              </li>
            )}
            {user && (
              <li className="">
                <a
                  onClick={() => {
                    // localStorage.setItem("isLogged", "false");
                    // setIsLogged(false);
                    //TODO : logout
                    signOut(auth);
                    nav("/");
                  }}
                  className=" py-2 px-3 cursor-pointer flex justify-center items-center text-red-500 rounded hover:bg-gray-100 lg:hover:bg-transparent lg:border-0 lg:hover:text-red-700 lg:p-0 "
                >
                  Se déconnecter
                </a>
              </li>
            )}
          </ul>
        </div>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex w-10 items-center p-2 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
}

interface Service {
  title: string;
  description: string;
  image: string;
}

const services: Service[] = [
  {
    title: "Système d'Information Géographique",
    description:
      "Le Bureau El Moustapha Consulting (BEMC), offrant des solutions innovantes pour la cartographie, la modélisation et l'analyse spatiale. Grâce à une équipe spécialisée, BEMC utilise les dernières technologies pour fournir des systèmes géographiques intégrés qui permettent une visualisation précise des données spatiales, facilitant ainsi la prise de décision dans divers secteurs.",
    image: geo,
  },
  {
    title: "L'environnement",
    description:
      "Engagé envers la durabilité et la responsabilité environnementale, BEMC propose des solutions complètes pour relever les défis environnementaux. Son expertise englobe la gestion des ressources naturelles, l'évaluation des impacts environnementaux et la conception de stratégies écologiques. BEMC s'efforce de contribuer à la préservation de l'écosystème en intégrant des approches novatrices pour promouvoir un développement respectueux de l'environnement.",
    image: env,
  },
  {
    title: "Séminaires et programmes de formation",
    description:
      "BEMC se distingue par son engagement envers le développement professionnel grâce à des séminaires et programmes de formation de haute qualité. En mettant l'accent sur l'actualisation des compétences et l'acquisition de nouvelles connaissances, BEMC favorise l'épanouissement individuel et collectif, contribuant ainsi à l'amélioration continue dans un monde en constante évolution.",
    image: info,
  },
  {
    title: "Organisation et ressources humaines",
    description:
      "BEMC excelle dans l'optimisation des structures organisationnelles et la gestion des ressources humaines. En offrant des solutions sur mesure pour améliorer l'efficacité opérationnelle, promouvoir le leadership et favoriser un environnement de travail positif, BEMC contribue à renforcer la capacité des organisations à atteindre leurs objectifs tout en mettant en avant le potentiel de chaque individu au sein de l'équipe.",
    image: formation,
  },
  {
    title: "Solutions Informatiques",
    description:
      "Dans le domaine des solutions informatiques, BEMC se positionne en tant que partenaire de confiance pour répondre aux besoins technologiques variés des entreprises. Que ce soit pour le développement d'applications sur mesure, la gestion de bases de données complexes ou la mise en œuvre de solutions cloud, BEMC garantit des solutions informatiques efficaces et adaptées aux défis spécifiques de chaque client.",
    image: humans,
  },
];

const sponsors = [
  sponsor01,
  sponsor02,
  sponsor03,
  sponsor04,
  // sponsor05,
  sponsor06,
  sponsor07,
  sponsor08,
  // sponsor12,
];

function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleClick() {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      await addDoc(collection(db, "messages"), {
        name,
        email,
        text: message,
      });
      setName("");
      setEmail("");
      setMessage("");
      alert("Message envoyé avec succès");
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  }

  return (
    <div className="rounded-xl bg-white flex-col flex lg:w-1/2 px-4 md:px-12 py-14 shadow-xl">
      <h3 className="text-2xl font-semibold text-center p-4 mb-9">
        N'hésitez pas à nous contacter
      </h3>
      <h6 className=" text-sm self-start font-semibold text-gray-700">Nom</h6>
      <input
        placeholder="Entrez votre nom"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        className="mb-5 bg-white shadow-sm border rounded-lg py-3 pl-2"
      />
      <h6 className=" text-sm self-start font-semibold text-gray-700">Email</h6>
      <input
        placeholder="Entrez une adress email valide"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        className="mb-5 bg-white shadow-sm border rounded-lg py-3 pl-2"
      />
      <h6 className=" text-sm self-start font-semibold text-gray-700">
        Message
      </h6>
      <textarea
        placeholder="Votre message"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        className="mb-5 bg-white shadow-sm border rounded-lg py-3 pl-2"
      />
      <button
        disabled={name.length < 3 || email.length < 3 || message.length < 3}
        onClick={handleClick}
        className="bg-blue-500 hover:bg-blue-400 text-white py-4 font-semibold text-xl px-4 rounded-lg mt-3 lg:mt-12 transition-all cursor-pointer active:scale-95 disabled:bg-gray-400"
      >
        {isLoading ? (
          <svg
            aria-hidden="true"
            className="w-8 h-8 text-gray-200 animate-spin  fill-blue-600 mx-auto"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        ) : (
          "Envoyer"
        )}
      </button>
    </div>
  );
}
