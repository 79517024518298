export default function DefInput(
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
) {
  return (
    <input
      {...props}
      className={
        "text-xl p-3 border border-slate-300 rounded shadow-sm " +
        props.className
      }
    />
  );
}
