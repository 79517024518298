import { NavBar } from "./home_page";
import img1 from "../../assets/images/b1.jpeg";
import { useContext, useEffect, useState } from "react";
import { db, storage } from "../../firebase";
import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { CurrentUserContext } from "../../App";
import { ConfirmatinDialog } from "./info_page";

const statusChoices = ["in_progress", "done"];
type ProjectStatus = (typeof statusChoices)[number];

interface Project {
  id: string;
  title: string;
  status: ProjectStatus;
  image: string | null;
  category: string;
}

export function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 3) | 8;
    return v.toString(16);
  });
}

function ProjectDialog({ onSubmit }: { onSubmit: () => void }) {
  const [img, setImg] = useState<{ file: File; url: string } | null>(null);
  const [title, setTitle] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [status, setStatus] = useState<ProjectStatus>("in_progress");
  const [isUploading, setIsUploading] = useState(false);
  async function uploadProject() {
    setIsUploading(true);
    const ext = img?.file.name.split(".").pop();
    const uid = uuid();
    const imageRef = ref(storage, `projects/${uid}.${ext}`);

    try {
      await setDoc(doc(db, "projects", uid), {
        title,
        status: status === "done" ? 1 : 0,
        image: `projects/${uid}.${ext}`,
        category: category,
      });

      // upload image to storage
      await uploadBytes(imageRef, img?.file as Blob);
    } catch (e) {
      console.error(e);
    }
    setIsUploading(false);
    onSubmit();
  }

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="bg-white shadow-xl rounded-xl p-4 w-[600px] aspect-[3/2] flex-col flex gap-y-2"
    >
      <div className="flex items-center justify-center w-full">
        {img ? (
          <img
            className="w-full h-64 rounded-xl shadow-xl object-cover "
            src={img.url}
          />
        ) : (
          <label className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>

              <p className="text-xs text-gray-500 ">SVG, PNG, JPG</p>
            </div>
            <input
              onChange={(e) => {
                const file = e.target.files?.item(0);

                if (file) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    setImg({
                      file: file,
                      url: e.target?.result as string,
                    });
                  };
                  reader.readAsDataURL(file);
                }
              }}
              id="dropzone-file"
              type="file"
              className="hidden"
            />
          </label>
        )}
      </div>
      <input
        placeholder="Titre"
        className=" text-xl py-2 focused:px-4"
        value={title}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => setTitle(e.target.value)}
      />
      <input
        placeholder="Catégorie"
        onChange={(
          e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => setCategory(e.target.value)}
        className=" text-md py-2 focused:px-4"
        value={category}
      />
      <div className="flex gap-x-2 mb-2">
        <label className="font-semibold">Status :</label>
        <select
          id="cars"
          name="cars"
          value={status}
          onChange={(e) => {
            setStatus(e.target.value as ProjectStatus);
          }}
        >
          <option value="in_progress">En cours</option>
          <option value="done">Terminé</option>
        </select>
      </div>
      <button
        onClick={uploadProject}
        disabled={
          title === "" || category === "" || img === null || isUploading
        }
        className={`bg-blue-500 rounded-full w-min text-white py-2 px-4 self-end hover:bg-blue-600 active:scale-95 transition-all disabled:bg-gray-500 ${
          isUploading ? " animate-pulse" : ""
        }`}
      >
        {/* uploading in french */}
        {isUploading ? "Téléchargement..." : "Ajouter"}
      </button>
    </div>
  );
}
function ProjectItem({
  project,
  onDeleteClick,
}: {
  project: Project | null;
  onDeleteClick: () => void;
}) {
  const user = useContext(CurrentUserContext).user;
  return (
    <div
      className={`flex flex-col items-start w-full md:w-[600px] mb-10 rounded-xl ${
        !project && "animate-pulse"
      }`}
    >
      {project && project!.status === "in_progress" && (
        <div className="flex gap-x-3 items-center mb-1">
          <div className=" bg-amber-400 w-3 h-3 rounded-full animate-pulse" />
          <h3 className=" font-medium"> En Cours</h3>
        </div>
      )}
      {project && project.status === "done" && (
        <div className="flex gap-x-3 items-center mb-1">
          <div className=" bg-green-400 w-3 h-3 rounded-full" />
          <h3 className=" font-medium"> Terminer</h3>
        </div>
      )}
      {!project || !project.image ? (
        <div className="flex items-center justify-center h-96 w-full bg-gray-300 rounded-xl">
          <svg
            className="w-10 h-10 text-gray-200 "
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 20"
          >
            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
          </svg>
        </div>
      ) : (
        <img
          className="w-full h-96 rounded-xl shadow-xl object-cover "
          src={project.image}
        />
      )}
      <div className="flex w-full justify-between items-start mt-2">
        {project ? (
          <h3 className=" text-2xl text-left line-clamp-2 font-medium">
            {project.title}
          </h3>
        ) : (
          <div className="h-2.5 bg-gray-200 rounded-full  w-96 mb-4"></div>
        )}
        {project && user && (
          <button
            onClick={onDeleteClick}
            className=" rounded-full border  border-red-500 text-red-500 px-3 text-sm h-fit py-1 shadow-none transition-all hover:bg-red-100 active:scale-95"
          >
            Supprimer
          </button>
        )}
      </div>
      {project ? (
        <h4 className="text-gray-800 text-sm">{project.category}</h4>
      ) : (
        <div className="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
      )}
    </div>
  );
}

export function ProjectsPage() {
  const [selected, setSelected] = useState<ProjectStatus | null>(null);
  const [projects, setProjects] = useState<Project[] | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deletingDoc, setDeletingDoc] = useState<string | null>(null);

  async function fetch(status: ProjectStatus | null) {
    setProjects(null);
    var q = query(collection(db, "projects"));

    if (status !== null) {
      q = query(q, where("status", "==", status === "done" ? 1 : 0));
    }

    let ret: Project[] = [];
    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      const data = doc.data();

      const image = ref(storage, data.image);

      let url = null;
      try {
        url = await getDownloadURL(image);
      } catch (e) {
        console.error(e);
      }

      ret.push({
        id: doc.id,
        title: data.title,
        status: data.status === 0 ? "in_progress" : "done",
        image: url,
        category: data.category,
      });
    }

    setProjects(ret);
  }

  useEffect(() => {
    fetch(null);
  }, []);

  const user = useContext(CurrentUserContext).user;
  return (
    <div className="flex flex-col w-full">
      {(isDialogOpen || deletingDoc) && (
        <div
          onClick={() => {
            setIsDialogOpen(false);
          }}
          className=" absolute inset-0 flex justify-center items-center  backdrop-blur-sm z-50"
        >
          {deletingDoc ? (
            <ConfirmatinDialog
              onSubmit={function (): void {
                setDeletingDoc(null);
                deleteDoc(doc(db, "projects", deletingDoc));
                fetch(selected);
              }}
              onCancel={function (): void {
                setDeletingDoc(null);
              }}
            />
          ) : (
            <ProjectDialog
              onSubmit={function (): void {
                setIsDialogOpen(false);
                fetch(selected);
              }}
            />
          )}
        </div>
      )}
      <NavBar />

      <div className="flex flex-col mx-auto  items-center w-full xl:w-[calc(1200px+12px+20px)]">
        <div className="flex justify-between gap-x-2 items-center w-full px-4">
          <h3 className=" text-4xl font-semibold my-12   self-start text-slate-700 ">
            Nos Projets
          </h3>
          {user && (
            <button
              onClick={() => {
                setIsDialogOpen(true);
              }}
              className="border border-blue-500 rounded-full py-2 px-4 font-semibold text-blue-600 transition-all hover:bg-blue-500 hover:text-white active:scale-95"
            >
              Ajouter
            </button>
          )}
        </div>
        <div className="flex items-center justify-center py-4 md:py-8 flex-wrap pl-4">
          <button
            type="button"
            onClick={() => {
              setSelected(null);
              fetch(null);
            }}
            className={`text-base font-medium px-5 py-2.5 text-center me-3 mb-3 rounded-full border-2 active:scale-95  transition-all ${
              selected === null ? "border-blue-500 text-blue-600" : ""
            }`}
          >
            Tous
          </button>
          {statusChoices.map((status) => (
            <button
              type="button"
              onClick={() => {
                setSelected(status);
                fetch(status);
              }}
              className={`text-base font-medium px-5 py-2.5 text-center me-3 mb-3 rounded-full border-2 active:scale-95  transition-all ${
                selected === status
                  ? `${
                      status === "done"
                        ? "border-green-500 text-green-600"
                        : "border-amber-500 text-amber-600"
                    } `
                  : ""
              }`}
            >
              {status === "in_progress" ? "En cours" : "Terminer"}
            </button>
          ))}
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 justify-items-center gap-x-5 mx-4">
          {projects?.map((project) => (
            <ProjectItem
              project={project}
              onDeleteClick={() => {
                setDeletingDoc(project.id);
              }}
            />
          )) ?? [
            <ProjectItem project={null} onDeleteClick={() => {}} />,
            <ProjectItem project={null} onDeleteClick={() => {}} />,
            <ProjectItem project={null} onDeleteClick={() => {}} />,
            <ProjectItem project={null} onDeleteClick={() => {}} />,
          ]}
        </div>
      </div>
    </div>
  );
}
