import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db, storage } from "../../firebase";
import { NavBar } from "./home_page";

interface NewsDetail {
  id: string;
  title: string;
  type: string;
  image: string | null;
  link: string | null;
  detail: string | null;
}

export default function NewsPageDetailPage() {
  // get new id from url
  const id = useParams<{ id: string }>().id;
  const [newsDetail, setNewsDetail] = useState<NewsDetail | null>(null);

  const [img, setImg] = useState<{ file: File; url: string } | null>(null);

  async function fetch() {
    if (!id) return;
    const docSnap = await getDoc(doc(db, "news", id!.toString()));
    const data = docSnap.data();
    if (!data) return;

    const image = ref(storage, data.image);
    let url = null;
    try {
      url = await getDownloadURL(image);
    } catch (e) {
      console.error(e);
    }

    setNewsDetail({
      id: docSnap.id,
      title: data.title,
      type: data.type,
      image: url,
      link: data.link ?? null,
      detail: data.detail ?? null,
    });
  }

  useEffect(() => {
    fetch();
  }, []);
  return (
    <div className="flex flex-col ">
      <NavBar />
      <div className="flex flex-col w-[95%] md:w-3/4 lg:w-1/2 mx-auto items-center">
        <div className="flex flex-col items-center ">
          <h1 className="text-4xl font-bold mt-20  mb-10 self-start">
            {newsDetail?.title}
          </h1>
          <div className="flex flex-col w-full items-center">
            {newsDetail?.image && (
              <img
                src={newsDetail?.image}
                alt="news image"
                className="w-full h-[500px] object-cover shadow-xl"
              />
            )}
            {newsDetail?.link && (
              <a href={newsDetail?.link} target="_blank" rel="noreferrer">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  {newsDetail?.link}
                </button>
              </a>
            )}
            {/* replace all */}
            <p
              className="text-start font-normal text-md md:text-lg mt-10"
              dangerouslySetInnerHTML={{
                __html: newsDetail?.detail?.replace(/<br\/>/g, "<br>") ?? "",
              }}
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
}
