import React, { createContext, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { HomePage } from "./UI/Pages/home_page";
import { Outlet, Link, BrowserRouter, Route, Routes } from "react-router-dom";
import { GalleryPage } from "./UI/Pages/gallery_page";
import { InfoPage } from "./UI/Pages/info_page";
import { NewsPage } from "./UI/Pages/news_page";
import { ProjectsPage } from "./UI/Pages/projects_page";
import { MessagesPage } from "./UI/Pages/messages_page";
import { AdminPage } from "./UI/Pages/admin_page";
import { User, getAuth } from "firebase/auth";
import NewsPageDetailPage from "./UI/Pages/new_page_detail";

export const CurrentUserContext = createContext<UserContextInterface>(
  {} as UserContextInterface,
);

interface UserContextInterface {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

function App() {
  const [user, setUser] = React.useState<User | null>(null);
  const auth = getAuth();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  // redirect to / if 404

  return (
    <CurrentUserContext.Provider value={{ user: user, setUser: setUser }}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/portef" element={<InfoPage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            {user && <Route path="/messages" element={<MessagesPage />} />}
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/news/:id" element={<NewsPageDetailPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </CurrentUserContext.Provider>
  );
}

export default App;
