import { Link, useNavigate } from "react-router-dom";
import { NavBar } from "./home_page";
import { useContext, useState } from "react";
import { error } from "console";
import { CurrentUserContext } from "../../App";
import {
  getAuth,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthCredential,
  EmailAuthProvider,
  updatePassword,
} from "firebase/auth";

function DefDiv({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-col justify-center w-[95%] sm:w-[500px] gap-y-4 bg-white p-6 rounded shadow-xl">
      {children}
    </div>
  );
}
function LogInPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<null | string>(null);

  const setUser = useContext(CurrentUserContext).setUser;
  const navigate = useNavigate();

  const auth = getAuth();
  return (
    <div className="h-screen flex items-center justify-center bg-gray-50">
      <DefDiv>
        <h3 className="self-start font-semibold mb-2 text-xl">
          {"Se connecter"}
        </h3>
        <input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError(null);
          }}
          placeholder="Email"
          className=" border rounded shadow py-2 px-4 text-xl "
        />
        <input
          value={password}
          type="password"
          onChange={(e) => {
            setPassword(e.target.value);
            setError(null);
          }}
          placeholder="Mot de passe"
          className=" border rounded shadow py-2 px-4 text-xl "
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}

        <button
          onClick={() => {
            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                const user = userCredential.user;
                setUser(user);
              })
              .catch((error) => {
                setError("Veuillez vérifier vos identifiants");
              });
          }}
          disabled={email.length < 5 || password.length < 6}
          className="border border-blue-500 rounded-full py-2 px-4 font-semibold text-blue-600 transition-all hover:bg-blue-500 hover:text-white active:scale-95
          disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Se connecter
        </button>
      </DefDiv>
    </div>
  );
}

function AdminLoggedPage() {
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<null | string>(null);

  function clearFields() {
    setOldPassword("");
    setPassword("");
    setConfirmPassword("");
    setError(null);
  }
  return (
    <div className="flex flex-col h-[100vh] bg-gray-50">
      <NavBar />
      <div className="flex justify-center items-center h-full ">
        {isUpdatingPassword ? (
          <DefDiv>
            <h3 className="self-start font-semibold mb-2 text-xl">
              {"Changer le mot de passe"}
            </h3>
            <input
              type="password"
              value={oldPassword}
              onChange={(e) => {
                setOldPassword(e.target.value);
                setError(null);
              }}
              placeholder="Ancien mot de passe"
              className=" border rounded shadow py-2 px-4 text-xl "
            />
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError(null);
              }}
              placeholder="Nouveau mot de passe"
              className=" border rounded shadow py-2 px-4 text-xl "
            />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setError(null);
              }}
              placeholder="Confirmer le mot de passe"
              className=" border rounded shadow py-2 px-4 text-xl "
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <button
              className="border border-blue-500 rounded-full py-2 px-4 font-semibold text-blue-600 transition-all hover:bg-blue-500 hover:text-white active:scale-95"
              onClick={() => {
                if (password !== confirmPassword) {
                  setError("Les mots de passe ne correspondent pas");
                  return;
                }
                const user = getAuth().currentUser;
                const email = user!.email!;
                const credential = EmailAuthProvider.credential(
                  email,
                  oldPassword,
                );
                reauthenticateWithCredential(user!, credential)
                  .then(() => {
                    updatePassword(user!, password)
                      .then(() => {
                        setIsUpdatingPassword(false);

                        alert("Mot de passe changé avec succès");
                        clearFields();
                        setIsUpdatingPassword(false);
                      })
                      .catch((error) => {
                        setError("Une erreur est survenue");
                      });
                  })
                  .catch((error) => {
                    setError("Veuillez vérifier vos identifiants");
                  });
              }}
            >
              Valider
            </button>
          </DefDiv>
        ) : (
          <DefDiv>
            <h1 className="text-3xl font-semibold mb-6">
              {"Bienvenue sur la page d'administration"}
            </h1>
            <Link
              to="/messages"
              className="border border-blue-500 rounded-full py-2 px-4 font-semibold text-blue-600 transition-all hover:bg-blue-500 hover:text-white active:scale-95"
            >
              {"Voir les messages"}
            </Link>
            <button
              onClick={() => setIsUpdatingPassword(true)}
              className="border border-blue-500 rounded-full py-2 px-4 font-semibold text-blue-600 transition-all hover:bg-blue-500 hover:text-white active:scale-95"
            >
              Changer le mot de passe
            </button>
            <button
              className="border border-red-500 rounded-full py-2 px-4 font-semibold text-red-600 transition-all hover:bg-red-500 hover:text-white active:scale-95"
              onClick={() => {
                getAuth().signOut();
              }}
            >
              {"Se déconnecter"}
            </button>
          </DefDiv>
        )}
      </div>
    </div>
  );
}

export function AdminPage() {
  const user = useContext(CurrentUserContext).user;

  return !user ? <LogInPage /> : <AdminLoggedPage />;
}
