import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// config value from add firebase sdk script that showed earlier.
const config = {
  apiKey: "AIzaSyBKQZ4Ny7A4cvSXYEMPzG6CF1LKhGkCy3c",
  authDomain: "bemc-17e9d.firebaseapp.com",
  projectId: "bemc-17e9d",
  storageBucket: "bemc-17e9d.appspot.com",
  messagingSenderId: "155794942870",
  appId: "1:155794942870:web:d65ec3bfbd4f57c2bccbb7",
};

export const app = initializeApp(config);

export const db = getFirestore(app);
export const storage = getStorage(app);
